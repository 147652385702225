.user {
  display: flex;
  align-items: center;
  font-size: 1.25em;

  .large {
    font-size: 1.5em;
  }
}

.userImage {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(#007bff, 0);
  transition: box-shadow .3s ease-out;

  .dealer & {
    box-shadow: 0 0 4px 5px #007bff;
  }
}

.userPoints {
  font-size: .7em;
  opacity: 0.5;
}
