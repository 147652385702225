.gameArea {
  position: relative;
  flex: 1 1 0px;
  min-height: 1px;
}

.gameToolbar {
  flex: 0 0 auto;
  background: rgba(0, 0, 0, 0.3);
}
