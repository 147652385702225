:root {
  --card-size-width: 60px;
  --card-size-height: 90px;
  --card-size-padding: 4px;

  @media (min-width: 576px) {
    --card-size-width: 75px;
    --card-size-height: 112px;
    --card-size-padding: 6px;
  }
}

.playerCard {
  display: block;
  z-index: 1;
  width: var(--card-size-width);
  height: var(--card-size-height);
  padding: var(--card-size-padding);
  border: 1px solid #eee;
  border-radius: 4px;
  background: white;
  box-shadow: -1px 0 4px rgba(0, 0, 0, 0.3);
  line-height: 1.25;
  transition: all .3s ease-out;

  &.red { color: red; }
  &.black { color: black; }

  &.clickable {
    cursor: pointer;
    &:hover {
      transform: translateY(calc(-1/3 * var(--card-size-height)));
    }
  }

  + .playerCard {
    margin-left: 10px;
  }

  .stackedCards & {
    margin-left: 0;
    margin-right: calc(-3/5 * var(--card-size-width));
    &.unknown { margin-right: calc(-3/4 * var(--card-size-width)); }
    &:last-child { margin-right: 0; }
  }

  .tightStack & {
    &.unknown { margin-right: calc(-8/9 * var(--card-size-width)); }
    &:last-child { margin-right: 0; }
  }
}

.unknown {
  background: #007bff;
  border-width: 5px;
}

.ghost {
  background: transparent;
  border: none;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
}

.cardNumber {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
