.gameTable {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //padding: 32px;

  .stackedCards {
    position: relative;
  }
}

.deckCounter {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  font-size: 2em;
  font-weight: bold;
  opacity: 0.5;
}

.deckActions,
.tableActions,
.gameActions {
  display: none;
  position: absolute;

  .gameTable:hover & {
    display: block;
  }
}

.deckActions {
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}

.tableActions {
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
}

.gameActions {
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
