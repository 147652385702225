.player {
  .stackedCards {
    position: relative;

    .handActions,
    .revealedActions {
      display: none;
      position: absolute;
      top: 50%;
    }

    &:hover {
      .handActions,
      .revealedActions {
        display: block;
      }
    }

    .handActions {
      left: 0;
      transform: translate(-100%, -50%);
    }

    .revealedActions {
      right: 0;
      transform: translate(100%, -50%);
    }
  }

  .handCards {
    &:not(:empty) {
      min-width: 180px;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
  }
}
