.header {
  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    //max-height: 50vh;
    min-height: 20vh;

    @media (min-width: 576px) {
      min-height: 40vh;
    }
  }
}

.header,
.content {
  .form-control {
    min-width: 210px;
    @media (min-width: 992px) {
      min-width: 300px;
    }
  }
}

.content {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    //max-height: 50vh;
    min-height: 40vh;

    @media (min-width: 576px) {
      min-height: 40vh;
    }
  }

  > :first-child::before {
    content: 'or';
    display: block;
    position: absolute;
    z-index: 2;
    bottom: 0;
    padding: 16px;
    background: #282c34;
    transform: translateY(50%);

    @media (min-width: 576px) {
      bottom: initial;
      right: 0;
      transform: translateX(50%);
    }
  }

  > :first-child::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    @media (min-width: 576px) {
      width: 0;
      border-bottom: none;

      right: 0;
      height: 100%;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
}
